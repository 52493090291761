import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_ADMINISTRACAO_PORT}${process.env.VUE_APP_API_PATH}/administracao`;

const formDataType = {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
};

export default {
    obterTodos() {
        return axiosJwt.get(`${api}/templates`);
    },

    obterPorId(id) {
        return axiosJwt.get(`${api}/templates/${id}`);
    },

    inserir(template) {
        const formData = new FormData();
        formData.append('codigo', template.codigo);
        formData.append('descricao', template.descricao);
        formData.append('dados', template.dados);
        if (template.arquivo) {
            formData.append('arquivo', template.arquivo);
        }
        return axiosJwt.post(`${api}/templates`, formData, formDataType);
    },

    atualizar(template) {
        const formData = new FormData();
        formData.append('templateId', template.templateId);
        formData.append('codigo', template.codigo);
        formData.append('descricao', template.descricao);
        formData.append('dados', template.dados);
        if (template.arquivo) {
            formData.append('arquivo', template.arquivo);
        }
        return axiosJwt.put(`${api}/templates`, formData, formDataType);
    },

    download(templateId) {
        return axiosJwt.get(`${api}/templates/${templateId}/download`, {
            responseType: 'blob',
        });
    },
};
