<template>
    <template-form
        acao="ATUALIZAR"
        @salvar="atualizar($event)"
        :cancelar="cancelar"
        :erros="erros"
        :template="template"
        @atualizar="obterTemplate()"
    ></template-form>
</template>

<script>
import TemplateForm from './TemplateForm';
import TemplatesService from './services';

export default {
    components: {
        TemplateForm,
    },

    data() {
        return {
            erros: [],
            template: null,
        };
    },

    computed: {},

    methods: {
        cancelar() {
            this.$router.push({
                name: `Templates_Detalhar`,
                params: {
                    id: this.$route.params.id,
                },
            });
        },

        obterTemplate() {
            this.$store.dispatch('addRequest');
            TemplatesService.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.template = response.data;
                } else {
                    this.template = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar(templateAtualizado) {
            this.$store.dispatch('addRequest');
            let templateAtualizadoDto = {
                templateId: this.$route.params.id,
                codigo: templateAtualizado.codigo,
                descricao: templateAtualizado.descricao,
                dados: templateAtualizado.dados,
            };
            if (templateAtualizado.arquivo) {
                templateAtualizadoDto.arquivo = templateAtualizado.arquivo;
            }
            TemplatesService.atualizar(templateAtualizadoDto).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Templates',
                        detail: 'Template atualizado com sucesso',
                        life: 3000,
                    });
                    this.$store.dispatch('setAtualizar', true);
                    this.template = response.data;
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    mounted() {
        this.obterTemplate();
    },
};
</script>
